<template>
  <span>
    <a-button @click="visible = true" type="primary">高级查询</a-button>

    <a-modal :visible="visible" title="高级查询" @cancel="cancel" :footer="null" width="800px">
      <a-form :form="form" :colon="false" @submit="handleSubmit">
        <a-row :gutter="8">
          <a-col :span="8">
            <a-form-item label="设计编号">
              <a-input v-decorator="['code']" />
            </a-form-item>
          </a-col>
          <a-col :span="8">
            <a-form-item label="合同名称">
              <a-input v-decorator="['name']" />
            </a-form-item>
          </a-col>
          <a-col :span="8">
            <a-form-item label="业主单位">
              <CompanySelector @change="(company) => (selectedCompany = company)">
                <a-button
                  block
                  style="margin-top: 4px; text-align: left"
                  class="ellipsis"
                >{{ selectedCompany.name }}</a-button>
              </CompanySelector>
            </a-form-item>
          </a-col>
          <a-col :span="8">
            <a-form-item label="专业">
              <a-select
                show-search
                :filter-option="$selectFilterOption"
                v-decorator="[
                  'projectType',
                ]"
                style="width: 100%"
                placeholder
              >
                <a-select-option v-for="item in majorList" :key="item.value" :value="item.value">
                  {{
                  item.name }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :span="8">
            <a-form-item label="设计阶段">
              <a-select
                show-search
                :filter-option="$selectFilterOption"
                mode="multiple"
                v-decorator="[
                  'phase',
                ]"
                style="width: 100%"
                placeholder
              >
                <a-select-option v-for="item in phaseList" :key="item.value" :value="item.value">
                  {{
                  item.name }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :span="8">
            <a-form-item label="合同金额(万)" style="margin-bottom: 0">
              <a-form-item style="width: 45%; display: inline-block">
                <a-input-number
                  :formatter="$inputNumberFormat"
                  v-decorator="['contractAmountMin']"
                  style="width: 100%"
                />
              </a-form-item>

              <span style="width: 10%; display: inline-block; text-align: center">-</span>

              <a-form-item style="width: 45%; display: inline-block">
                <a-input-number
                  :formatter="$inputNumberFormat"
                  v-decorator="['contractAmountMax']"
                  style="width: 100%"
                />
              </a-form-item>
            </a-form-item>
          </a-col>
          <a-col :span="8">
            <a-form-item label="责任部门">
              <a-select
                show-search
                :filter-option="$selectFilterOption"
                v-decorator="[
                  'productionUnitId',
                ]"
                style="width: 100%"
                placeholder
              >
                <a-select-option
                  v-for="item in productionDepartmentList"
                  :key="item.id"
                  :value="item.id"
                >
                  {{
                  item.uniqueName }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :span="8">
            <a-form-item label="所属区域">
              <a-select
                style="width: 100%"
                placeholder
                v-decorator="[
                  'areaId',
                ]"
              >
                <a-select-option
                  v-for="item in areaList"
                  :key="item.id"
                  :value="item.id"
                >{{item.areaName }}</a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :span="8">
            <a-form-item label="区域负责人">
              <div>
                <EmployeeSelector
                  @change="(arr) => {
                    if (arr.length) {
                      selectedUser = arr[0];
                    } else {
                      selectedUser = {};
                    }
                  }
                    "
                  :value="selectedUser.name ? [selectedUser] : []"
                >
                  <a-button block style="text-align: left">
                    <div v-if="selectedUser.name">{{ selectedUser.name }}</div>
                    <div v-else>&nbsp;</div>
                  </a-button>
                </EmployeeSelector>
              </div>
            </a-form-item>
          </a-col>
          <a-col :span="8">
            <a-form-item label="归档情况">
              <a-select
                style="width: 100%"
                v-decorator="[
                  'contractSignStatus',
                ]"
              >
                <a-select-option
                  v-for="item in filteredSignStatusList"
                  :key="item.value"
                  :value="item.value"
                >{{ item.name }}</a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :span="8">
            <a-form-item label="承接时间">
              <a-range-picker
                :placeholder="[]"
                v-decorator="[
                  'date',
                ]"
                format="YYYY-MM"
                :mode="['month', 'month']"
                :allowClear="true"
                @panelChange="panelChange"
                :open="open"
                @openChange="openChange"
              />
            </a-form-item>
          </a-col>
        </a-row>

        <div class="right">
          <a-space>
            <a-button @click="cancel">取消</a-button>
            <a-button type="primary" html-type="submit">查询</a-button>
          </a-space>
        </div>
      </a-form>
    </a-modal>
  </span>
</template>

<script>
import area from "@/mixins/area";
import organization from "@/mixins/organization";
import { mapGetters } from "vuex";
import EmployeeSelector from "@/components/employee-selector";
import CompanySelector from "@/components/company-selector";

import accurate from "accurate";

export default {
  mixins: [organization, area],

  components: {
    CompanySelector,
    EmployeeSelector
  },

  data() {
    return {
      form: this.$form.createForm(this),
      visible: false,
      selectedUser: {},
      selectedCompany: {},
      open: false
    };
  },

  computed: {
    ...mapGetters("setting", ["findDataDict"]),

    phaseList() {
      return this.findDataDict("phase");
    },
    signStatusList() {
      return this.findDataDict("contractSignStatus");
    },
    filteredSignStatusList() {
      return this.signStatusList.filter(
        item => item.value !== "winbid" && item.value !== "tracking"
      );
    },
    majorList() {
      return this.findDataDict("contract_scale");
    }
  },

  methods: {
    panelChange(value) {
      this.form.setFieldsValue({
        date: value
      });

      this.open = false;
    },
    openChange(value) {
      this.open = value;
    },

    cancel() {
      this.visible = false;
    },

    handleSubmit(e) {
      e.preventDefault();
      this.form.validateFields((err, values) => {
        if (!err) {
          this.save(values);
        }
      });
    },

    save(values) {
      this.saveLoading = true;
      let contractAmountMin, contractAmountMax;
      if (typeof values.contractAmountMin === "number") {
        contractAmountMin = accurate.expr(
          `${values.contractAmountMin}*${10000}`
        );
      }
      if (typeof values.contractAmountMin === "number") {
        contractAmountMax = accurate.expr(
          `${values.contractAmountMax}*${10000}`
        );
      }

      let areaName;
      if (values.areaId) {
        areaName = this.areaList.find(item => item.id === values.areaId)
          .areaName;
      }
      // let undertakingDateStart, undertakingDateEnd;
      // if (Array.isArray(values.date) && values.date.length === 2) {
      //   undertakingDateStart = values.date[0].format("YYYY-MM");
      //   undertakingDateEnd = values.date[1].format("YYYY-MM");
      // }

      let phase, phaseName;
      if (values.phase) {
        phase = values.phase.join(",");

        phaseName = values.phase
          .map(
            item => this.phaseList.find(element => element.value === item).name
          )
          .join(",");
      }

      let productionUnitName;
      if (values.productionUnitId) {
        productionUnitName = this.productionDepartmentList.find(
          item => item.id === values.productionUnitId
        ).uniqueName;
      }

      let projectTypeName;
      if (values.projectType) {
        projectTypeName = this.majorList.find(
          item => item.value === values.projectType
        ).name;
      }

      this.$emit("change", {
        ...values,

        contractAmountMin,
        contractAmountMax,
        areaName,

        areaMasterId: this.selectedUser.userId,
        areaMasterName: this.selectedUser.name,

        partyAid: this.selectedCompany.id,
        partyAname: this.selectedCompany.name,

        // date: undefined,
        // undertakingDateStart,
        // undertakingDateEnd,

        productionUnitName,
        projectTypeName,

        phase,
        phaseName
      });

      this.cancel();
    }
  }
};
</script>