var render = function render(){var _vm=this,_c=_vm._self._c;return _c('span',[_c('a-button',{attrs:{"type":"primary"},on:{"click":function($event){_vm.visible = true}}},[_vm._v("高级查询")]),_c('a-modal',{attrs:{"visible":_vm.visible,"title":"高级查询","footer":null,"width":"800px"},on:{"cancel":_vm.cancel}},[_c('a-form',{attrs:{"form":_vm.form,"colon":false},on:{"submit":_vm.handleSubmit}},[_c('a-row',{attrs:{"gutter":8}},[_c('a-col',{attrs:{"span":8}},[_c('a-form-item',{attrs:{"label":"设计编号"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['code']),expression:"['code']"}]})],1)],1),_c('a-col',{attrs:{"span":8}},[_c('a-form-item',{attrs:{"label":"合同名称"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['name']),expression:"['name']"}]})],1)],1),_c('a-col',{attrs:{"span":8}},[_c('a-form-item',{attrs:{"label":"业主单位"}},[_c('CompanySelector',{on:{"change":(company) => (_vm.selectedCompany = company)}},[_c('a-button',{staticClass:"ellipsis",staticStyle:{"margin-top":"4px","text-align":"left"},attrs:{"block":""}},[_vm._v(_vm._s(_vm.selectedCompany.name))])],1)],1)],1),_c('a-col',{attrs:{"span":8}},[_c('a-form-item',{attrs:{"label":"专业"}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'projectType',
              ]),expression:"[\n                'projectType',\n              ]"}],staticStyle:{"width":"100%"},attrs:{"show-search":"","filter-option":_vm.$selectFilterOption,"placeholder":""}},_vm._l((_vm.majorList),function(item){return _c('a-select-option',{key:item.value,attrs:{"value":item.value}},[_vm._v(" "+_vm._s(item.name)+" ")])}),1)],1)],1),_c('a-col',{attrs:{"span":8}},[_c('a-form-item',{attrs:{"label":"设计阶段"}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'phase',
              ]),expression:"[\n                'phase',\n              ]"}],staticStyle:{"width":"100%"},attrs:{"show-search":"","filter-option":_vm.$selectFilterOption,"mode":"multiple","placeholder":""}},_vm._l((_vm.phaseList),function(item){return _c('a-select-option',{key:item.value,attrs:{"value":item.value}},[_vm._v(" "+_vm._s(item.name)+" ")])}),1)],1)],1),_c('a-col',{attrs:{"span":8}},[_c('a-form-item',{staticStyle:{"margin-bottom":"0"},attrs:{"label":"合同金额(万)"}},[_c('a-form-item',{staticStyle:{"width":"45%","display":"inline-block"}},[_c('a-input-number',{directives:[{name:"decorator",rawName:"v-decorator",value:(['contractAmountMin']),expression:"['contractAmountMin']"}],staticStyle:{"width":"100%"},attrs:{"formatter":_vm.$inputNumberFormat}})],1),_c('span',{staticStyle:{"width":"10%","display":"inline-block","text-align":"center"}},[_vm._v("-")]),_c('a-form-item',{staticStyle:{"width":"45%","display":"inline-block"}},[_c('a-input-number',{directives:[{name:"decorator",rawName:"v-decorator",value:(['contractAmountMax']),expression:"['contractAmountMax']"}],staticStyle:{"width":"100%"},attrs:{"formatter":_vm.$inputNumberFormat}})],1)],1)],1),_c('a-col',{attrs:{"span":8}},[_c('a-form-item',{attrs:{"label":"责任部门"}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'productionUnitId',
              ]),expression:"[\n                'productionUnitId',\n              ]"}],staticStyle:{"width":"100%"},attrs:{"show-search":"","filter-option":_vm.$selectFilterOption,"placeholder":""}},_vm._l((_vm.productionDepartmentList),function(item){return _c('a-select-option',{key:item.id,attrs:{"value":item.id}},[_vm._v(" "+_vm._s(item.uniqueName)+" ")])}),1)],1)],1),_c('a-col',{attrs:{"span":8}},[_c('a-form-item',{attrs:{"label":"所属区域"}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'areaId',
              ]),expression:"[\n                'areaId',\n              ]"}],staticStyle:{"width":"100%"},attrs:{"placeholder":""}},_vm._l((_vm.areaList),function(item){return _c('a-select-option',{key:item.id,attrs:{"value":item.id}},[_vm._v(_vm._s(item.areaName))])}),1)],1)],1),_c('a-col',{attrs:{"span":8}},[_c('a-form-item',{attrs:{"label":"区域负责人"}},[_c('div',[_c('EmployeeSelector',{attrs:{"value":_vm.selectedUser.name ? [_vm.selectedUser] : []},on:{"change":(arr) => {
                  if (arr.length) {
                    _vm.selectedUser = arr[0];
                  } else {
                    _vm.selectedUser = {};
                  }
                }}},[_c('a-button',{staticStyle:{"text-align":"left"},attrs:{"block":""}},[(_vm.selectedUser.name)?_c('div',[_vm._v(_vm._s(_vm.selectedUser.name))]):_c('div',[_vm._v(" ")])])],1)],1)])],1),_c('a-col',{attrs:{"span":8}},[_c('a-form-item',{attrs:{"label":"归档情况"}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'contractSignStatus',
              ]),expression:"[\n                'contractSignStatus',\n              ]"}],staticStyle:{"width":"100%"}},_vm._l((_vm.filteredSignStatusList),function(item){return _c('a-select-option',{key:item.value,attrs:{"value":item.value}},[_vm._v(_vm._s(item.name))])}),1)],1)],1),_c('a-col',{attrs:{"span":8}},[_c('a-form-item',{attrs:{"label":"承接时间"}},[_c('a-range-picker',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'date',
              ]),expression:"[\n                'date',\n              ]"}],attrs:{"placeholder":[],"format":"YYYY-MM","mode":['month', 'month'],"allowClear":true,"open":_vm.open},on:{"panelChange":_vm.panelChange,"openChange":_vm.openChange}})],1)],1)],1),_c('div',{staticClass:"right"},[_c('a-space',[_c('a-button',{on:{"click":_vm.cancel}},[_vm._v("取消")]),_c('a-button',{attrs:{"type":"primary","html-type":"submit"}},[_vm._v("查询")])],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }