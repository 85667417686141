<template>
  <span>
    <a href="#" @click.prevent="visible = true">详情</a>

    <a-modal
      :visible="visible"
      :title=" title2? title2 + '-' + name : '当年承接项目-' + name"
      :footer="null"
      @cancel="visible = false"
    >
      <a-table bordered :data-source="list" :loading="loading" :pagination="false">
        <a-table-column title="部门">
          <template slot-scope="text">
            <span>
              {{text.deptName ? text.deptName : text.areaName
              }}
            </span>
          </template>
        </a-table-column>
        <a-table-column title="承接项目(万)" align="right">
          <template slot-scope="text">
            <span
              style="color: #1890ff"
            >{{typeof text.amount === 'number' ? text.amount.toLocaleString() : 0}}</span>
          </template>
        </a-table-column>

        <a-table-column title="操作" align="center">
          <template slot-scope="text">
            <a href="#" @click.prevent="search(text)">查询</a>
          </template>
        </a-table-column>
      </a-table>
    </a-modal>
  </span>
</template>

<script>
import request from "@/api/request";

function fetchList(params) {
  return request({
    url: "/project-service/contractUndertaking/dept/year/sum",
    params
  });
}

export default {
  props: {
    id: {
      type: String,
      default: ""
    },
    title2: {
      type: String,
      default: ""
    },
    name: {
      type: String,
      default: ""
    },
    contractSignStatus: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      visible: false,
      loading: false,
      list: []
    };
  },
  watch: {
    visible() {
      if (this.visible) {
        this.loading = true;

        fetchList({
          type: this.id,
          contractSignStatus: this.contractSignStatus
        })
          .then(res => {
            this.list = Array.isArray(res) ? res : [];
          })
          .finally(() => {
            this.loading = false;
          });
      }
    }
  },

  methods: {
    search(text) {
      let params = [];

      if (text.deptId) {
        params.push({
          type: "deptId",
          value: text.deptId
        });
      } else {
        params.push({
          type: "areaId",
          value: text.areaId
        });
      }

      params.push({
        type: "year"
      });

      params.push({
        type: "contractSignStatus",
        value: this.contractSignStatus
      });

      if (params.length > 0) {
        this.$emit("search", params);
        this.visible = false;
      }
    }
  }
};
</script>