<template>
  <div>
    <span style="cursor: pointer;" @click="visible = true">
      <a-icon type="setting" />
    </span>

    <a-modal title="修改当年已承接总承包项目" :visible="visible" :footer="null" @cancel="visible = false">
      <a-input-number :min="0" v-model="value" style="width: 240px" placeholder="请输入" />

      <Padding />
      <Padding />

      <div class="right">
        <a-space>
          <a-button @click="visible = false">关闭</a-button>
          <a-button type="primary" @click="save" :loading="saveLoading">保存</a-button>
        </a-space>
      </div>
    </a-modal>
  </div>
</template>

<script>
import request from "@/api/request";

function change(data) {
  return request({
    url: "/project-service/contractUndertaking/count",
    method: "post",
    data
  });
}
export default {
  props: {
    count: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      visible: false,
      value: 0,
      saveLoading: false
    };
  },
  watch: {
    visible() {
      if (this.visible) {
        this.value = this.count;
      } else {
        this.value = 0;
      }
    }
  },
  methods: {
    save() {
      if (typeof this.value !== "number") {
        this.$message.error("请输入数字");
        return;
      }

      this.saveLoading = true;

      change({
        count: this.value
      })
        .then(() => {
          this.$emit("refresh");
          this.visible = false;
        })
        .finally(() => {
          this.saveLoading = false;
        });
    }
  }
};
</script>

