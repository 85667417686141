<template>
  <div>
    <input ref="upload" style="display: none" type="file" :multiple="false" @change="onChange" />
    <a-button
      :loading="loading"
      @click="onClick"
    >{{ type === 'not-sign' ? '已中标未签订合同导入' : '其他合同导入' }}</a-button>
  </div>
</template>
  
<script>
import request from "@/api/request";

function importOtherContract(data) {
  return request({
    url: "/project-service/contractUndertaking/import",
    method: "post",
    data
  });
}
function importNotSignContract(data) {
  return request({
    url: "/project-service/contractUndertaking/importNotSign",
    method: "post",
    data
  });
}

export default {
  props: {
    type: {
      type: String,
      default: ""
    }
  },

  data() {
    return {
      loading: false
    };
  },

  methods: {
    onClick() {
      this.$refs.upload.click();
    },

    onChange(e) {
      const files = e.target.files;
      if (files.length === 0) return;

      const data = new FormData();
      for (let i = 0; i < files.length; i++) {
        data.append("file", files[i]);
      }

      this.loading = true;

      let ask = importOtherContract;
      if (this.type === "not-sign") {
        ask = importNotSignContract;
      }

      ask(data)
        .then(() => {
          this.$emit("refresh");
        })
        .finally(() => {
          this.loading = false;
          e.target.value = null;
        });
    }
  }
};
</script>