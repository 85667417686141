<template>
  <div>
    <Pane :bottom="0" :showRemarks="false">
      <a-tabs
        v-if="showMenu"
        :tabBarStyle="{
                    margin: '0',
                  }"
        v-model="active"
      >
        <a-tab-pane v-for="item in tabs" :key="item.value" :tab="item.name"></a-tab-pane>
      </a-tabs>

      <template v-slot:extra>
        <a-space style="margin-top: 12px; margin-right: -16px" v-if="active === 'contract' && showMenu">
          <a-button
            @click="$router.push($route.path + '/add')"
            v-if="$getPermission($route.path + '/add')"
            type="primary"
          >新建合同</a-button>
        </a-space>

        <a-space style="margin-top: 12px; margin-right: -16px" v-if="active === 'undertaking' && showMenu">
          <a-button
            type="primary"
            v-if="$getPermission($route.path + '/undertaking/add')"
            @click="$router.push($route.path + '/undertaking/add')"
          >新建项目</a-button>

          <Upload
            type="other"
            v-if="$getPermission($route.path + '/undertaking/imoprt-other')"
            @refresh="refresh"
          />
          <Upload
            type="not-sign"
            v-if="$getPermission($route.path + '/undertaking/import-not-sign')"
            @refresh="refresh"
          />

          <a-button
            type="primary"
            v-if="$getPermission($route.path + '/undertaking/download')"
            @click="download"
          >导出</a-button>
        </a-space>
      </template>
    </Pane>

    <Contract v-show="active === 'contract'" />
    <Undertaking
      v-show="active === 'undertaking'"
      :refreshFlag="refreshFlag"
      :downloadFlag="downloadFlag"
    />
  </div>
</template>

<script>
import Contract from "./contract.vue";
import Undertaking from "./undertaking/index.vue";
import Upload from "./undertaking/upload.vue";

export default {
  name: "contract",

  components: {
    Contract,
    Undertaking,
    Upload
  },

  data() {
    return {
      showMenu: true,
      active: "",
      refreshFlag: "",
      downloadFlag: "",
      tabs: [
        {
          name: "合同签订管理",
          value: "contract"
        }
      ]
    };
  },

  activated() {
    const { from } = this.$route.query;
    if(from == 'dataBoard'){
      this.showMenu = false
    }
    if (this.$getPermission(this.$route.path + "/undertaking")) {
      if (!this.tabs.find(item => item.value === "undertaking")) {
        this.tabs.unshift({
          name: "年度承接项目管理",
          value: "undertaking"
        });
      }
      this.active = "undertaking";
    } else {
      this.active = "contract";
    }
  },

  methods: {
    refresh() {
      this.refreshFlag = Date.now().toString();
    },
    download() {
      this.downloadFlag = Date.now().toString();
    }
  }
};
</script>