<template>
  <a-card class="container">
    <a-form-model :model="form" :colon="false" layout="inline" @keyup.enter.native="query">
      <a-row>
        <a-col :span="24">
          <a-form-model-item>
            <a-input
              placeholder="设计编号"
              v-model="form.code"
              style="width: 150px"
              list="designCodeList2"
            ></a-input>
            <datalist id="designCodeList2">
              <option v-for="item in designCodeList" :key="item">{{ item }}</option>
            </datalist>
          </a-form-model-item>
          <a-form-model-item label>
            <a-input placeholder="合同名称" v-model="form.name" style="width: 150px" />
          </a-form-model-item>
          <a-form-model-item>
            <a-select placeholder="合同类型" v-model="form.contractType" style="width: 150px">
              <a-select-option
                v-for="item in typeList"
                :key="item.value"
                :value="item.value"
              >{{ item.name }}</a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item>
            <a-select placeholder="归档情况" v-model="form.contractSignStatus" style="width: 150px">
              <a-select-option
                v-for="item in filteredContractSignStatusList"
                :key="item.value"
                :value="item.value"
              >
                {{ item.name
                }}
              </a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item>
            <a-select placeholder="所属区域" v-model="form.areaId" style="width: 150px">
              <a-select-option v-for="item in areaList" :key="item.id" :value="item.id">
                {{ item.areaName
                }}
              </a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item>
            <a-select
              placeholder="生产部门"
              show-search
              :filter-option="$selectFilterOption"
              v-model="form.oriProductionUnitId"
              style="width: 150px"
            >
              <a-select-option
                v-for="item in productionDepartmentList"
                :key="item.id"
                :value="item.id"
              >
                {{
                item.uniqueName }}
              </a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item>
            <a-select
              placeholder="责任部门"
              show-search
              :filter-option="$selectFilterOption"
              v-model="form.productionUnitId"
              style="width: 150px"
            >
              <a-select-option
                v-for="item in productionDepartmentList"
                :key="item.id"
                :value="item.id"
              >
                {{
                item.uniqueName }}
              </a-select-option>
            </a-select>
          </a-form-model-item>

          <a-form-model-item>
            <a-date-picker
              mode="year"
              placeholder="年份"
              @panelChange="panelChange"
              :value="form.year"
              format="YYYY"
              :open="open"
              @openChange="openChange"
              style="width: 120px"
              :allowClear="false"
            />
          </a-form-model-item>

          <a-form-model-item v-if="form.year">
            <a-range-picker
              :mode="['month', 'month']"
              :placeholder="['月份', '月份']"
              :value="form.selectedMonthList"
              format="MM"
              style="width: 120px"
              @panelChange="monthPanelChange"
              :allowClear="false"
            />
          </a-form-model-item>
          <a-form-model-item>
            <a-space>
              <a-button @click="query" type="primary">查询</a-button>
              <a-button @click="reset">重置</a-button>
            </a-space>
          </a-form-model-item>
        </a-col>
      </a-row>
    </a-form-model>

    <Padding />

    <a-table
      bordered
      :dataSource="list"
      :loading="loading"
      @change="onChange"
      :pagination="{
                    total,
                    current,
                    pageSize,
                    showTotal: (total) => `共 ${total} 条记录`,
                  }"
    >
      <a-table-column title="设计编号" width="150px">
        <template slot-scope="text">
          <div class="between">
            <router-link :to="`/produce/progress?code=${text.code}`" title="查看关联项目">{{ text.code }}</router-link>
            <a-icon type="copy" @click="$copy(text.code)" style="color: #999" title="复制" />
          </div>
        </template>
      </a-table-column>
      <a-table-column title="合同名称">
        <template slot-scope="text">
          <router-link
            :title="text.name"
            :to="`/produce/progress?code=${text.code}`"
          >{{ text.name }}</router-link>
        </template>
      </a-table-column>

      <a-table-column title="合同金额(万元)" data-index="contractAmount" align="right">
        <template slot-scope="contractAmount">
          <span style="color: #1890ff">
            <Money :money="contractAmount" />
          </span>
        </template>
      </a-table-column>
      <a-table-column title="合同类型" data-index="type" align="center">
        <template slot-scope="type">
          <DataDictFinder dictType="contractType" :dictValue="type" />
        </template>
      </a-table-column>
      <a-table-column title="所属区域" data-index="areaName"></a-table-column>
      <a-table-column title="生产部门" data-index="oriProductionUnitName"></a-table-column>
      <a-table-column title="责任部门" data-index="productionUnitNameUnique"></a-table-column>
      <a-table-column title="生产负责人">
        <template slot-scope="text">
          <div class="between">
            <div>
              <span v-if="text.productionMasterName">
                {{
                text.productionMasterName
                }}
              </span>
            </div>

            <a-tooltip v-if="$getPermission($route.path + '/update-production-master')">
              <template slot="title">指派</template>

              <EmployeeSelector @change="onUpdateMaster">
                <a
                  href="#"
                  style="padding-right: 8px; color: #f00"
                  @click="customUpdateMaster(text)"
                >
                  <span class="iconfont icon-fenpeijiaose" style="color: #1890ff"></span>
                </a>
              </EmployeeSelector>
            </a-tooltip>
          </div>
        </template>
      </a-table-column>

      <a-table-column title="归档情况" align="center">
        <template slot-scope="text">
          <DataDictFinder dictType="contractSignStatus" :dictValue="text.contractSignStatus" />
        </template>
      </a-table-column>

      <a-table-column title="配置管理" data-index="configureStatus" align="center">
        <template slot-scope="configureStatus">
          <DataDictFinder
            dictType="taskReviewStatus"
            :dictValue="configureStatus"
            iconType="badge"
          />
        </template>
      </a-table-column>

      <a-table-column align="center" title="操作">
        <template slot-scope="text">
          <!-- 补偿合同跳转到另一个地址 -->
          <template v-if="text.type === 'compensated'">
            <private-button
              v-if="editButtonVisible(text)"
              :disabled="editDisabled(text)"
              path="edit"
              :params="{ id: text.id }"
            >
              <span>编辑</span>
            </private-button>
          </template>
          <template v-else>
            <private-button v-if="editButtonVisible(text)" path="edit" :params="{ id: text.id }">
              <span>编辑</span>
            </private-button>
          </template>

          <a-tooltip title="右键打开新页面" v-if="$getPermission($route.path + '/detailWithoutStatus')">
            <a-button
              type="link"
              @click="$router.push($route.path + '/detail?id=' + text.id)"
              @contextmenu.prevent="goDetail($route.path + '/detail?id=' + text.id)"
            >查看</a-button>
          </a-tooltip>

          <private-button path="delete" routeType="method" danger @clickButton="deleteText(text)">
            <span>删除</span>
          </private-button>
        </template>
      </a-table-column>
    </a-table>
  </a-card>
</template>

<script>
import area from "@/mixins/area";
import organization from "@/mixins/organization";
import watermark from "@/mixins/watermark";
import {
  fetchList,
  remove,
  updateMaster as updateMasterInfo
} from "@/api/contract";
import { checkUserRole } from "./utils";
import { mapGetters, mapState } from "vuex";
import moment from "moment";
import EmployeeSelector from "@/components/employee-selector";

export default {
  mixins: [area, organization, watermark],

  components: {
    EmployeeSelector
  },

  data() {
    return {
      form: {},

      open: false, // 年份选择器显示隐藏

      loading: false,
      current: 1,
      pageSize: 10,
      list: [],
      total: 0,

      selectedContract: {}, // 选中的合同，编辑生产负责人的时候用

      designCodeList: []
    };
  },

  computed: {
    ...mapGetters("setting", ["findDataDict"]),
    contractSignStatusList() {
      return this.findDataDict("contractSignStatus");
    },

    filteredContractSignStatusList() {
      return this.contractSignStatusList.filter(
        item => item.value !== "tracking" && item.value !== "winbidNotSign"
      );
    },

    typeList() {
      return this.findDataDict("contractType");
    },

    // 当前用户信息
    ...mapState("auth", ["user"]),
    // 当前用户必须是院长，才可以配置生产负责人，判断是否是院长
    ...mapGetters("auth", ["isProduction", "isPresident"])
  },

  activated() {
    const { query } = this.$route;
    const { code } = query || {};

    if (code) {
      this.form = {
        code
      };
    }

    this.getList();

    const str = window.localStorage.getItem("search-design-code");
    if (str) {
      this.designCodeList = JSON.parse(str);
    }
  },

  methods: {
    // 年份选择器
    panelChange(value) {
      this.form.year = value;
      this.open = false;
      // 同时设置月份选择1月到12月
      const startDate = moment(value.format("YYYY") + "-01-01");
      const endDate = moment(value.format("YYYY") + "-12-01");
      this.form.selectedMonthList = [startDate, endDate];
    },
    // 年份选择器面板显示隐藏
    openChange(value) {
      this.open = value;
    },
    // 自定义月份选择器点击选择监听
    monthPanelChange(values) {
      if (values.length === 2) {
        this.form = {
          ...this.form,
          selectedMonthList: [
            moment(values[0].format("YYYY-MM-DD")),
            moment(values[1].format("YYYY-MM-DD"))
          ]
        };
      }
    },

    getList() {
      this.saveDesignCode();

      const { current, pageSize, form } = this;
      const {
        code,
        name,
        areaId,
        oriProductionUnitId,
        productionUnitId,
        contractType,
        year,
        selectedMonthList,
        contractSignStatus
      } = form;
      let startDate, endDate;
      if (Array.isArray(selectedMonthList) && selectedMonthList.length === 2) {
        startDate =
          year.format("YYYY") + "-" + selectedMonthList[0].format("MM") + "-01";

        const nextMonthStr = moment(selectedMonthList[1].format("YYYY-MM-DD"))
          .add(1, "months")
          .format("MM");
        const nextMonthObj = moment(
          year.format("YYYY") + "-" + nextMonthStr + "-01"
        ).subtract(1, "days");
        endDate = year.format("YYYY") + "-" + nextMonthObj.format("MM-DD");
      }

      this.loading = true;
      fetchList({
        pageNum: current,
        pageSize,
        code,
        name,
        contractSignStatus,
        oriProductionUnitId,
        productionUnitId,
        contractType,
        areaId,
        startDate,
        endDate
      })
        .then(res => {
          this.loading = false;
          if (Array.isArray(res.list)) {
            this.list = res.list.map(item => {
              // epc合同对应：type：EPC合同  &  unionType为lead
              // 设计合同就是其他所有合同
              let type;
              if (item.type === "EPC合同" && item.unionType === "lead") {
                type = "EPC合同";
              } else {
                type = "设计合同";
              }
              return {
                ...item,
                customType: type
              };
            });
            this.total = res.totalSize;
          }
        })
        .catch(() => {
          this.loading = false;
        });
    },

    onChange(pagination) {
      this.current = pagination.current;
      this.pageSize = pagination.pageSize;
      this.getList();
    },

    deleteText(text) {
      const that = this;
      this.$confirm({
        title: "确认要删除这个合同吗？",
        onOk() {
          remove({
            id: text.id
          }).then(() => {
            that.getList();
          });
        }
      });
    },

    // 合同编辑按钮在合同任务审批中的时候禁用
    // 合同编辑按钮在合同类型为补偿合同，角色为生产负责人时，禁用
    editDisabled(text) {
      return (
        text.configureStatus === "reviewing" ||
        (this.isProduction && text.customType === "compensated")
      );
    },

    // 当前登录用户是生产管理角色，并且在合同任务审批中和审批通过时，按钮不渲染
    editButtonVisible(text) {
      const { roles } = this.user;
      if (Array.isArray(roles)) {
        const index = roles.findIndex(
          item =>
            item.code === "product_contractManager_without_project" ||
            item.code === "product_contractManager"
        );
        if (index === -1) {
          if (
            text.configureStatus === "reviewing" ||
            text.configureStatus === "approved"
          ) {
            return false;
          } else {
            return true;
          }
        } else {
          return true;
        }
      } else {
        return true;
      }
    },

    customUpdateMaster(text) {
      console.log("用户点击修改负责人");
      this.selectedContract = text;
    },

    // 选择人员后更新生产负责人
    onUpdateMaster(selectedList) {
      // 当选择人员modal被关闭时触发

      const obj = selectedList[0];

      // 校验用户选择的人是否符合需求（不属于院长和合同管理员角色）
      checkUserRole(obj.userId)
        .then(() => {
          updateMasterInfo({
            id: this.selectedContract.id,
            productionMasterId: obj.userId,
            productionMasterName: obj.name
          }).then(() => {
            const index = this.list.findIndex(
              item => item.id === this.selectedContract.id
            );
            this.list.splice(index, 1, {
              ...this.selectedContract,
              productionMasterId: obj.userId,
              productionMasterName: obj.name
            });
          });
        })
        .catch(message => {
          this.$message.error(message);
        });
    },

    // 当合同在审批中和审批通过，都只有查看按钮
    detailButtonVisible(text) {
      return (
        text.configureStatus === "reviewing" ||
        text.configureStatus === "approved"
      );
    },

    query() {
      this.current = 1;
      this.getList();
    },
    reset() {
      this.form = {};

      this.current = 1;
      this.getList();
    },
    goDetail(path) {
      window.open(window.location.origin + "#" + path, "_blank");
    },

    saveDesignCode() {
      if (this.form.code) {
        if (this.designCodeList.indexOf(this.form.code) === -1) {
          this.designCodeList.unshift(this.form.code);
          if (this.designCodeList.length > 10) {
            this.designCodeList.pop();
          }
          window.localStorage.setItem(
            "search-design-code",
            JSON.stringify(this.designCodeList)
          );
        }
      }
    }
  }
};
</script>