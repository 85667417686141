<template>
  <span>
    <span @click="visible = true">
      <a-icon type="right" />
    </span>

    <a-modal :visible="visible" title="当月承接项目" :footer="null" @cancel="visible = false">
      <a-table bordered :data-source="list" :loading="loading" :pagination="false">
        <a-table-column title="组织类型" data-index="deptName" />
        <a-table-column title="承接项目(万)" align="right">
          <template slot-scope="text">
            <span
              style="color: #1890ff"
            >{{typeof text.amount === 'number' ? text.amount.toLocaleString() : 0}}</span>
          </template>
        </a-table-column>

        <a-table-column title="操作" align="center">
          <template slot-scope="text">
            <MonthDetail :id="text.deptId" :name="text.deptName" @search="onSearch" />
          </template>
        </a-table-column>
      </a-table>
    </a-modal>
  </span>
</template>

<script>
import MonthDetail from "./month-detail.vue";
import request from "@/api/request";

function fetchList() {
  return request({
    url: "/project-service/contractUndertaking/deptType/month/sum"
  });
}

export default {
  components: {
    MonthDetail
  },

  data() {
    return {
      visible: false,
      loading: false,
      list: []
    };
  },
  watch: {
    visible() {
      if (this.visible) {
        this.loading = true;

        fetchList()
          .then(res => {
            this.list = Array.isArray(res) ? res : [];
            this.list = this.list.filter(v=>v.deptName!=='区域经营')
          })
          .finally(() => {
            this.loading = false;
          });
      }
    }
  },

  methods: {
    onSearch(payload) {
      this.$emit("search", payload);
      this.visible = false;
    }
  }
};
</script>