<template>
  <a-card class="container">
    <div class="card" v-if="showMenu">
      <div class="item" :class="selectedTpye === 'month' ? 'active' : ''">
        <div class="title">
          <a-checkbox
            :checked="selectedTpye === 'month'"
            @change="e => onConditionChange('month',e.target.checked)"
          >当月承接项目(万)</a-checkbox>
          <span class="extra">
            <MonthList @search="onSearch" />
          </span>
        </div>
        <div
          class="value"
        >{{ typeof statistic.amountMonth === 'number' ? statistic.amountMonth.toLocaleString() : '--' }}</div>
      </div>
      <div class="item" :class="selectedTpye === 'year' ? 'active' : ''">
        <div class="title">
          <a-checkbox
            :checked="selectedTpye === 'year'"
            @change="e => onConditionChange('year', e.target.checked)"
          >当年承接项目(万)</a-checkbox>
          <span class="extra">
            <YearList @search="onSearch" />
          </span>
        </div>
        <div
          class="value"
        >{{ typeof statistic.amountYear === 'number' ? statistic.amountYear.toLocaleString() : '--' }}</div>
      </div>
      <div class="item" :class="selectedTpye === 'status' ? 'active' : ''">
        <div class="title">
          <a-checkbox
            :checked="selectedTpye === 'status'"
            @change="e => onConditionChange('status', e.target.checked)"
          >已承接合同未归档项目(万)</a-checkbox>
        </div>

        <div class="left">
          <span style="padding-right: 8px">签订中</span>
          <div
            class="value"
          >{{ typeof statistic.amountSigning === 'number' ? statistic.amountSigning.toLocaleString() : '--' }}</div>
          <span style="cursor: pointer; padding-left: 8px;padding-right: 16px">
            <YearList title="已承接合同未归档项目-签订中" contractSignStatus="signing" @search="onSearch" />
          </span>
          <span style="padding-right: 8px">已中标</span>
          <div
            class="value"
          >{{ typeof statistic.amountNotSign === 'number' ? statistic.amountNotSign.toLocaleString() : '--' }}</div>
          <span style="cursor: pointer; padding-left: 8px">
            <YearList title="已承接合同未归档项目-已中标" contractSignStatus="winbidNotSign" @search="onSearch" />
          </span>
        </div>
      </div>

      <div class="item">
        <div class="title">
          <span>当年已承接总承包项目</span>
        </div>

        <div class="between">
          <div
            class="value"
          >{{ typeof statistic.count === 'number' ? statistic.count.toLocaleString() : '--' }}</div>

          <Count
            :count="statistic.count"
            @refresh="getStatistic"
            v-if="$getPermission($route.path + '/undertaking/edit')"
          />
        </div>
      </div>
    </div>

    <Padding />

    <a-form-model layout="inline" :colon="false" :model="form" @keyup.enter.native="query">
      <a-row>
        <a-col :span="24">
          <a-form-model-item>
            <a-input placeholder="设计编号" v-model="form.code" style="width: 150px"></a-input>
          </a-form-model-item>

          <a-form-model-item>
            <a-input placeholder="合同名称" v-model="form.name" style="width: 150px"></a-input>
          </a-form-model-item>

          <a-form-model-item>
            <a-select
              placeholder="生产部门"
              show-search
              :filter-option="$selectFilterOption"
              v-model="form.productionUnitId"
              style="width: 150px"
            >
              <a-select-option
                v-for="item in productionDepartmentList"
                :key="item.id"
                :value="item.id"
              >
                {{ item.uniqueName
                }}
              </a-select-option>
            </a-select>
          </a-form-model-item>

          <a-form-model-item>
            <a-select placeholder="所属区域" style="width: 150px" v-model="form.areaId">
              <a-select-option v-for="item in areaList" :key="item.id" :value="item.id">
                {{
                item.areaName }}
              </a-select-option>
            </a-select>
          </a-form-model-item>

          <a-form-model-item>
            <a-cascader
              placeholder="项目地点"
              v-model="form.location"
              :options="cityList"
              :fieldNames="{
                  label: 'name',
                  value: 'name',
                  children: 'children',
                }"
            />
          </a-form-model-item>

          <a-form-model-item>
            <a-range-picker
              :placeholder="['承接开始时间', '承接结束时间']"
              :value="form.date"
              format="YYYY-MM"
              :mode="['month', 'month']"
              @panelChange="panelChange"
              :open="open"
              @openChange="openChange"
              :allowClear="true"
            />
          </a-form-model-item>

          <a-form-model-item>
            <a-space>
              <a-button @click="query" type="primary">查询</a-button>
              <a-button @click="reset">重置</a-button>
              <Search @change="onAdvancedSearch" />
            </a-space>
          </a-form-model-item>
        </a-col>
      </a-row>
    </a-form-model>

    <Padding />

    <a-table
      bordered
      :data-source="list"
      :loading="loading"
      @change="onChange"
      :pagination="{
                    total,
                    current,
                    pageSize,
                    showTotal: (total) => `共 ${total} 条记录`,
                  }"
      rowKey="id"
    >
      <a-table-column title="设计编号" width="120px">
        <template slot-scope="text">
          <span>
            <a
              href="#"
              v-if="$getPermission($route.path + '/detailWithoutStatus') && text.contractId"
              @click.prevent="$router.push($route.path + '/detail?id=' + text.contractId)"
            >{{ text.code }}</a>
            <span v-else>{{ text.code }}</span>
          </span>
        </template>
      </a-table-column>
      <a-table-column title="专业" data-index="projectTypeName" />
      <a-table-column title="业主单位" data-index="partyAname" />

      <a-table-column title="合同名称">
        <template slot-scope="text">
          <span
            :style="{color: text.sys && (text.name !== text.sys.name) ? 'red' : 'inherit'}"
          >{{ text.name}}</span>
        </template>
      </a-table-column>

      <a-table-column align="right">
        <div class="column-title" slot="title">
          <div>合同金额</div>
          <div>(万元)</div>
        </div>
        <template slot-scope="text">
          <Money :money="text.contractAmount" />
        </template>
      </a-table-column>

      <a-table-column title="生产部门">
        <template slot-scope="text">
          <div>{{ text.productionUnitName}}</div>
        </template>
      </a-table-column>


      <a-table-column title="地区">
        <template slot-scope="text">
          <span>{{ text.belongProvince }}{{text.belongCity}}{{text.district}}</span>
        </template>
      </a-table-column>

      <a-table-column title="设计阶段">
        <template slot-scope="text">{{ text.phaseName }}</template>
      </a-table-column>

      <a-table-column title="归档情况" align="center">
        <template slot-scope="text">
          <span>
            <DataDictFinder dictType="contractSignStatus" :dictValue="text.contractSignStatus" />
          </span>
        </template>
      </a-table-column>
      <a-table-column title="承接时间" align="center">
        <template slot-scope="text">{{ text.undertakingDate }}</template>
      </a-table-column>
      <a-table-column title="签订月份" data-index="signMonth"align="center"></a-table-column>
      <a-table-column align="center" title="操作" width="60px">
        <template slot-scope="text">
          <a
            href="#"
            v-if="$getPermission($route.path + '/undertaking/edit')"
            @click.prevent="$router.push($route.path + '/undertaking/edit?id=' + text.id)"
          >编辑</a>
        </template>
      </a-table-column>
    </a-table>
  </a-card>
</template>

<script>
import organization from "@/mixins/organization";
import watermark from "@/mixins/watermark";
import city from "@/mixins/city";
import area from "@/mixins/area";
import moment from "moment";
import { saveAs } from "file-saver";

import MonthList from "./month-list.vue";
import YearList from "./year-list.vue";
import Search from "./search.vue";

import Count from "./count.vue";

import request from "@/api/request";
import download from "@/api/download";

function fetchStatistic() {
  return request({
    url: "/project-service/contractUndertaking/sum"
  });
}

function fetchList(data) {
  return request({
    url: "/project-service/contractUndertaking/list",
    method: "post",
    data
  });
}

function downloadFile(data) {
  return download({
    url: "/project-service/contractUndertaking/export",
    method: "post",
    data
  });
}

export default {
  mixins: [organization, watermark, city, area],

  props: {
    refreshFlag: {
      type: String,
      default: ""
    },
    downloadFlag: {
      type: String,
      default: ""
    }
  },

  components: {
    MonthList,
    YearList,
    Search,
    Count
  },

  watch: {
    refreshFlag() {
      if (this.refreshFlag) {
        this.reset();
      }
    },
    downloadFlag() {
      if (this.downloadFlag) {
        this.download();
      }
    }
  },

  data() {
    return {
      designCodeList: [],
      open: false,

      statistic: {},
      form: {},

      selectedTpye: "",

      loading: false,
      current: 1,
      pageSize: 10,
      list: [],
      total: 0,
      showMenu: true
    };
  },
  activated() {
    this.getStatistic();

    const { code,id, year, from } = this.$route.query;
    if(from == 'dataBoard'){
      this.showMenu = false
    }
    if (code) {
      this.form = {
        code
      };
    }
    if(id){
      this.form.productionUnitId = id
    }
    if(year){
      this.form.date =[year + '-01', year + '-12']
    }
    this.getList();
  },

  methods: {
    getStatistic() {
      const hide = this.$message.loading("加载中...", 0);
      fetchStatistic()
        .then(res => {
          this.statistic = res || {};
        })
        .finally(() => {
          hide();
        });
    },

    panelChange(value) {
      this.form.date = value;
      this.open = false;
    },
    openChange(value) {
      this.open = value;
    },

    onSearch(payload) {
      if (Array.isArray(payload)) {
        this.form = {};
        payload.forEach(item => {
          if (item.type === "deptId") {
            this.form = {
              ...this.form,
              productionUnitId: item.value
            };
          } else if (item.type === "date") {
            const obj = moment(item.value);
            const obj2 = moment(item.value);
            this.form = {
              ...this.form,
              date: [obj, obj2]
            };
          } else if (item.type === "year") {
            this.form = {
              ...this.form,
              date: [moment().startOf("year"), moment().endOf("year")]
            };
          } else if (item.type === "areaId") {
            this.form = {
              ...this.form,
              areaId: item.value
            };
          } else if (item.type === "contractSignStatus") {
            this.form = {
              ...this.form,
              contractSignStatus: item.value
            };
          }
        });
      }

      this.current = 1;
      this.getList();
    },

    onConditionChange(key, value) {
      if (value) {
        this.selectedTpye = key;

        if (key === "month") {
          this.form = {
            date: [moment(), moment()]
          };
        } else if (key === "year") {
          this.form = {
            date: [moment().startOf("year"), moment().endOf("year")]
          };
        } else if (key === "status") {
          this.form = {
            contractSignStatus: "winbidNotSign,signing"
          };
        }
      } else {
        this.selectedTpye = "";
        this.form = {};
      }

      this.current = 1;
      this.getList();
    },

    getList() {
      const { current, pageSize } = this;
      this.loading = true;

      let undertakingDateStart, undertakingDateEnd;
      if (Array.isArray(this.form.date) && this.form.date.length === 2) {
        undertakingDateStart = moment(this.form.date[0]).format("YYYY-MM");
        undertakingDateEnd = moment(this.form.date[1]).format("YYYY-MM");
      }

      let belongProvince, belongCity, district;
      if (this.form.location) {
        belongProvince = this.form.location[0];
        belongCity = this.form.location[1];
        district = this.form.location[2];
      }

      fetchList({
        pageNum: current,
        pageSize: pageSize,
        ...this.form,

        date: undefined,
        undertakingDateStart,
        undertakingDateEnd,

        location: undefined,
        belongProvince,
        belongCity,
        district
      })
        .then(res => {
          if (Array.isArray(res.list)) {
            this.list = Object.freeze(res.list);
            this.total = res.totalSize ? res.totalSize : 0;
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },

    onChange(pagination) {
      this.current = pagination.current;
      this.pageSize = pagination.pageSize;
      this.getList();
    },

    query() {
      this.current = 1;
      this.getList();
    },
    reset() {
      this.form = {};
      this.selectedTpye = "";
      this.current = 1;
      this.getList();
    },

    onAdvancedSearch(payload) {
      console.log("payload", payload);
      this.form = {
        ...payload
      };
      this.selectedTpye = "";
      this.current = 1;
      this.getList();
    },

    download() {
      const hide = this.$message.loading("导出中...", 0);
      downloadFile({
        ...this.form
      })
        .then(blob => {
          saveAs(blob, "承接项目");
        })
        .catch(err => {
          console.log("err", err);
        })
        .finally(() => {
          hide();
        });
    }
  }
};
</script>
<style lang="less" scoped>
.column-title {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
}

.card {
  display: flex;

  .item {
    border: 2px solid #f0f0f0;
    margin-right: 12px;
    padding: 12px;
    border-radius: 2px;
    min-width: 240px;

    .title {
      margin-bottom: 6px;
      display: flex;
      align-items: center;
      .extra {
        margin-left: auto;
        cursor: pointer;
      }
    }

    .value {
      font-size: 2em;
      color: #1890ff;
    }
  }

  .active {
    border-color: #1890ff;
  }
}
</style>