<template>
  <span>
    <span @click="visible = true">
      <a-icon type="right" />
    </span>

    <a-modal
      :visible="visible"
      :title="title ? title :'当年承接项目'"
      :footer="null"
      @cancel="visible = false"
    >
      <div class="center" v-if="!title">
        <a-radio-group v-model="selectedTpye" button-style="solid">
          <a-radio-button value="dept">按部门</a-radio-button>
          <a-radio-button value="month">按月份</a-radio-button>
        </a-radio-group>
      </div>
      <template v-if="!title">
        <Padding />
        <Padding />
      </template>

      <div v-if="selectedTpye === 'dept'">
        <a-table bordered :data-source="list" :loading="loading" :pagination="false">
          <a-table-column title="组织类型" data-index="deptName" />
          <a-table-column title="承接项目(万)" align="right">
            <template slot-scope="text">
              <span
                style="color: #1890ff"
              >{{typeof text.amount === 'number' ? text.amount.toLocaleString() : 0}}</span>
            </template>
          </a-table-column>

          <a-table-column title="操作" align="center">
            <template slot-scope="text">
              <YearDetail
                :id="text.deptId"
                :title2="title"
                :name="text.deptName"
                @search="onSearch"
                :contractSignStatus="contractSignStatus"
              />
            </template>
          </a-table-column>
        </a-table>
      </div>
      <div v-else>
        <a-table bordered :data-source="list" :loading="loading" :pagination="false">
          <a-table-column title="月份" data-index="month" align="center" />
          <a-table-column title="承接项目(万)" align="right">
            <template slot-scope="text">
              <span
                style="color: #1890ff"
              >{{typeof text.amount === 'number' ? text.amount.toLocaleString() : 0}}</span>
            </template>
          </a-table-column>

          <a-table-column title="操作" align="center">
            <template slot-scope="text">
              <MonthDetail
                :id="text.deptId"
                :title2="title"
                :name="text.deptName"
                :month="text.month"
                @search="onSearch"
                :contractSignStatus="contractSignStatus"
              />
            </template>
          </a-table-column>
        </a-table>
      </div>
    </a-modal>
  </span>
</template>

<script>
import YearDetail from "./year-detail.vue";
import MonthDetail from "./month-detail.vue";
import request from "@/api/request";

function fetchYear(params) {
  return request({
    url: "/project-service/contractUndertaking/deptType/year/sum",
    params
  });
}

function fetchMonth(params) {
  return request({
    url: "/project-service/contractUndertaking/monthByYear/sum",
    params
  });
}

export default {
  props: {
    title: {
      type: String,
      default: ""
    },
    contractSignStatus: {
      type: String,
      default: ""
    }
  },
  components: {
    YearDetail,
    MonthDetail
  },

  data() {
    return {
      visible: false,
      selectedTpye: "dept",
      loading: false,
      list: []
    };
  },
  watch: {
    visible() {
      if (this.visible) {
        this.getData();
      }
    },

    selectedTpye() {
      this.getData();
    }
  },

  methods: {
    getData() {
      this.loading = true;

      let ask = fetchYear;
      if (this.selectedTpye === "month") {
        ask = fetchMonth;
      }

      ask({
        contractSignStatus: this.contractSignStatus
          ? this.contractSignStatus
          : undefined
      })
        .then(res => {
          this.list = Array.isArray(res) ? res : [];
          this.list = this.list.filter(v=>v.deptName!=='区域经营')
        })
        .finally(() => {
          this.loading = false;
        });
    },

    onSearch(payload) {
      this.$emit("search", payload);
      this.visible = false;
    }
  }
};
</script>